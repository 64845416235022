import { createConnector } from 'react-instantsearch-dom';

export default createConnector({
  displayName: 'BH_Location',

  getProvidedProps(props, searchState) {
    return {props, searchState};
  },

  refine(props, searchState, nextValue) {
    return {
      props,
      ...searchState,
      aroundLatLng: nextValue,
      boundingBox: {},
    };
  },

  getSearchParameters(searchParameters, props, searchState) {
    const currentRefinement = searchState.aroundLatLng;
     if (currentRefinement !== undefined) {
      return searchParameters
      .setQueryParameter('insideBoundingBox')
      .setQueryParameter(
        'aroundLatLng',
        `${currentRefinement.lat}, ${currentRefinement.lng}`
      ) 
     } else {
      return searchParameters
      .setQueryParameter('insideBoundingBox')
      .setQueryParameter(
        'aroundLatLngViaIP',
        true
      ) 
     }
  },
});