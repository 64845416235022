import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import connect from './connector';
//import MapboxAutocomplete from 'react-mapbox-autocomplete';
import ReactMapboxAutocomplete from "../search/react-mapbox-autocomplete/ReactMapboxAutocomplete";

//const googleplaces = typeof window !== `undefined` ? require("google-places.js") : null

class MapboxPlaces extends Component {
    static propTypes = {
        refine: PropTypes.func.isRequired,
        //defaultRefinement: PropTypes.object.isRequired,
    };

    createRef = c => (this.element = c);

    componentDidMount() {

    }

    _suggestionSelect(result, lat, lng, text) {
        //console.log(result, lat, lng, text);
        window.location.replace(`/search/?aroundLatLng%5Blat%5D=${lat}&aroundLatLng%5Blng%5D=${lng}&configure%5BhitsPerPage%5D=40&s=${text}`);
    }

    render() {
        return (
            <ReactMapboxAutocomplete
                ref={this.createRef}
                publicKey={process.env.MAPBOX_PLACES_API_KEY}
                inputClass='ap-input'
                onSuggestionSelect={this._suggestionSelect}
                country='us&types=place%2Cneighborhood%2Clocality%2Cpostcode&language=en'
                resetSearch={false}
                placeholder="Search by city or zip"
            />
        );
    }
}

export default connect(MapboxPlaces);